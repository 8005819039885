@keyframes loading-bar {
    0%,
    100% {
        transform: translate(-25%, 0);
    }
    50% {
        transform: translate(125%, 0);
    }
}

.loading-bar {
    height: 4px;
    margin: 0 auto;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transform: rotateY(0);
    transition: transform 0.3s ease-in;
}

.loading-bar .blue-bar {
    height: 100%;
    width: 50%;
    position: absolute;
    transform: translate(-25%, 0);
    background-color: #209cee;
    border-radius: 2px;
    animation: loading-bar 1.5s infinite ease;
}
