@import "~bulma/css/bulma.css";
.file-input {
    height: 100%;
    width: 100%;
}

.ck-editor__editable_inline:not(.ck-comment__input *) {
    height: 300px;
    overflow-y: auto;
}

.uploaded-file-preview img {
    max-height: 220px;
}

.menu-list a.is-active {
    background-color: #209cee;
}

.navbar-dropdown.box img {
    max-height: initial;
}

.navbar-link.icon-less {
    padding-right: 0.75rem;
}

.navbar-link.icon-less:after {
    border: none;
}

.image.is-rounded {
    border-radius: 250983px;
}

.image.is-rounded img {
    object-fit: cover;
    object-position: center center;
    height: 100%;
    width: 100%;
    border-radius: 250983px;
}

.columns.is-content-centered {
    justify-content: center;
}

.notification.is-fixed {
    z-index: 99;
    margin: 1rem;
    max-width: 30rem;
    position: fixed;
    top: 0;
    right: 0;
}

@media screen and (max-width: 768px) {
    .notification.is-fixed {
        max-width: initial;
        margin: 1rem;
    }
}

.input.tags-input {
    height: auto;
}

.input.tags-input input {
    width: auto;
}

.input.tags-input {
    height: auto;
}

.input-suggestion-wrapper {
    position: relative;
}

.input-suggestion-wrapper input {
    border: none !important;
    box-shadow: none !important;
    background: #fff;
}

.input-suggestion-wrapper .input-suggestion {
    padding: 3px 0;
    width: 150%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background: #fff;
}

.input-suggestion-wrapper .input-suggestion li {
    padding: 3px 10px;
    display: block;
    cursor: pointer;
    font-size: 0.75rem;
}

.input-suggestion-wrapper .input-suggestion li:hover {
    background-color: whitesmoke;
    color: #363636;
}

/* algolia modifier */

.ais-Highlight-highlighted {
    background: yellow;
}

span.required {
    padding-left: 2px;
    color: red;
}